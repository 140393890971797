@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jockey+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Georama:ital,wght@0,100..900;1,100..900&family=Lilita+One&display=swap');

body {
  margin: 0;
  font-family: "Lilita One", "Jockey One", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.georama {
  font-family: "Georama", "Jockey One", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  font-variation-settings:
    "wdth" 100;
  font-style: normal;
}

.reflected {
  transform: rotateY(180deg);
}

.react-responsive-modal-modal {
  border-radius: 10px;
  width: 100%;
  max-width: 1240px;
  margin: 0 !important;
}

.react-responsive-modal-container {
  padding-left: 20px;
  padding-right: 20px;
}

.swiper-slide {
  padding: 20px;
}

.swiper-pagination {
  display: none;
}

#gradient {
  background: rgb(128, 202, 255);
  background: linear-gradient(180deg, rgba(128, 202, 255, 1) 0%, rgba(187, 226, 255, 1) 100%);
}